'use client';

import { useEffect } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { PartCardLarge } from '@qb/frontend/components/PartCards/PartCardLarge';
import { BaseCarousel } from '@/components/Common/BaseCarousel/BaseCarousel';
import { useRequestPartPriceFromSPW } from '@/hooks/useRequestPartPriceFromSPW';
import { PartPopularPartsDataResponse } from '@/shared/types/controllers/sps/SPSPartControllerTypes';

// hardcoded now for popular parts
const SHIP_PROCESSING_DAYS = 0;
type Props = {
  parts: PartPopularPartsDataResponse['parts'];
};
export const PopularProducts = ({
  parts
}: Props) => {
  const {
    authUser,
    requestMultiplePartsPrices,
    getPartPrice
  } = useRequestPartPriceFromSPW();
  useEffect(() => {
    const partIDs = parts.map(({
      id
    }) => id);

    /**
     * Request the prices for the popular products.
     */
    requestMultiplePartsPrices(partIDs);
  }, [getPartPrice, parts, requestMultiplePartsPrices]);
  const partsWithDiscounts = parts.map(part => ({
    ...part,
    ...getPartPrice(part.id)
  }));
  const partsCount = parts?.length ?? 0;
  if (partsCount === 0) {
    return null;
  }
  return <Box sx={{
    bgcolor: 'primary.4p',
    py: 5
  }} data-sentry-element="Box" data-sentry-component="PopularProducts" data-sentry-source-file="PopularProducts.tsx">
      <Container component="section" data-sentry-element="Container" data-sentry-source-file="PopularProducts.tsx">
        <Typography component="h2" sx={{
        typography: {
          xs: 'h4',
          md: 'h3'
        },
        mb: {
          xs: 3,
          md: 4
        }
      }} data-sentry-element="Typography" data-sentry-source-file="PopularProducts.tsx">
          Popular products
        </Typography>
        <Box sx={{
        px: [1, 1, 2]
      }} data-sentry-element="Box" data-sentry-source-file="PopularProducts.tsx">
          <BaseCarousel slidesCount={partsCount} slidesToShow={{
          xs: 2,
          sm: 3,
          md: 4,
          lg: 5,
          xl: 5
        }} data-sentry-element="BaseCarousel" data-sentry-source-file="PopularProducts.tsx">
            {partsWithDiscounts.map(part => <Box key={part.id} sx={{
            px: {
              xs: 1
            }
          }}>
                <PartCardLarge authUserCompanyName={authUser?.company?.name} part={{
              ...part,
              price: part.price ?? undefined,
              originalPrice: part.originalPrice ?? undefined,
              shipProcessingDays: SHIP_PROCESSING_DAYS
            }} />
              </Box>)}
          </BaseCarousel>
        </Box>
      </Container>
    </Box>;
};